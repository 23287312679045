import { DescriptionBlock } from './page-components';
import React from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  chakra,
  Heading,
  Button
} from '@chakra-ui/react';
import { MapIcon, SparklesIcon } from '@heroicons/react/solid';
import { ThemeLoader } from './theme-loader';
import { ThemeBGLoader } from './theme-bg-loader';
import { CodeWindow } from '@components/common';
import Image from 'next/image';
import { motion, isValidMotionProp, useAnimation } from 'framer-motion';
import { ThemeProps } from './customization';
import { useRouter } from 'next/router';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || prop === 'children'
});

interface CustomizationPreviewProps {
  themes: ThemeProps[];
  setCurrentTheme: (currentTheme: ThemeProps) => void;
  currentTheme: ThemeProps;
  snippet: string;
  header: string;
  subheader: string;
  imgDirection: string;
  icon: string;
}

export function CustomizationPreviewDesktop({
  themes,
  setCurrentTheme,
  currentTheme,
  snippet,
  header,
  subheader,
  imgDirection,
  icon
}: CustomizationPreviewProps): JSX.Element {
  const [showCode, setShowCode] = React.useState<boolean>(false);

  const controls = useAnimation();

  const { pathname } = useRouter();

  const slideComponentStart = () => {
    controls.start({
      x: '40%',
      y: '25%',
      transition: {
        duration: 0.25,
        type: 'tween'
      },
      scale: [0.65, 0.65]
    });
  };
  const slideComponentReturn = () => {
    controls.start({
      x: '0%',
      y: '0%',
      transition: {
        duration: 0.25,
        type: 'tween'
      },
      scale: [0.65, 0.65]
    });
  };

  const handleOnClick = async (theme: ThemeProps) => {
    await controls.start({
      opacity: ['100%', '0%'],
      transition: {
        duration: 0.25,
        type: 'tween'
      }
    });
    await setCurrentTheme(theme);
    await controls.start({
      opacity: ['0%', '100%'],
      transition: {
        duration: 0.25,
        type: 'tween'
      }
    });
  };

  return (
    <>
      <Stack
        pos="relative"
        flexDir={imgDirection === 'right' ? 'row' : 'row-reverse'}
        justifyContent={'center'}
        alignItems="center"
        h="full"
        gap={4}
        w="100%"
        color={pathname === '/' ? 'white' : 'inherit'}
      >
        <Box pos="relative" w="100%">
          <Flex
            justifyContent={'flex-start'}
            flexDir="column"
            alignItems={'flex-start'}
          >
            <Box mt={-4} mb={4}>
              <Image src={icon} width={72} height={72} alt="" loading="lazy" />
            </Box>
            <Heading
              as="h3"
              size="h3"
              pt="0px"
              pb="16px"
              textAlign={'left'}
              color={pathname === '/' ? 'white' : 'inherit'}
            >
              {header}
            </Heading>
            <Text
              textAlign={'left'}
              fontSize={'24px'}
              fontFamily={'heading'}
              pb="30px"
            >
              {subheader}
            </Text>
          </Flex>
          <Box>
            <DescriptionBlock
              name="Appearance"
              icon={SparklesIcon}
              description={`Modify the theming of the prebuilt UIs. Compatible with Tailwind
            CSS, Chakra UI, Styled Components, custom CSS, and more. Light,
            Retro, Yolo...we can handle it all.`}
            />

            <DescriptionBlock
              name="Localization"
              icon={MapIcon}
              description={`Override the default strings for any element to localize to any
            language or change the wording to match your brand.`}
            />
          </Box>
        </Box>

        <Flex flexDir={'column'}>
          <Box w="full" justifySelf={'center'}>
            <Flex
              flexWrap="wrap"
              justifyContent={{ base: 'center', xl: 'space-evenly' }}
              zIndex={10}
              pos="relative"
              top={4}
              w="full"
              gap={0}
            >
              {themes.map((theme: ThemeProps) => {
                return (
                  <Button
                    key={theme.name}
                    py="10px"
                    px="16px"
                    mx={1}
                    my={
                      currentTheme?.name == '<OrganizationProfile/>' ||
                      currentTheme?.name == '<OrganizationSwitcher/>'
                        ? 0
                        : { base: 2, xl: 0 }
                    }
                    color={
                      currentTheme?.name === theme.name
                        ? 'white'
                        : 'primary.500'
                    }
                    bg={
                      currentTheme?.name === theme.name
                        ? 'primary.500'
                        : 'white'
                    }
                    rounded={'2xl'}
                    h="32px"
                    boxShadow={
                      currentTheme?.name === theme.name
                        ? '0px 0px 15px rgba(108, 71, 255, 0.75)'
                        : '0px 1px 2px rgba(57, 67, 86, 0.08);'
                    }
                    textStyle="paragraph-sm"
                    fontSize="12px"
                    borderColor={
                      currentTheme?.name === theme.name
                        ? 'primary.300'
                        : 'transparent'
                    }
                    onClick={() => {
                      handleOnClick(theme);
                    }}
                    _hover={{
                      color: 'white',
                      bg: 'primary.500',
                      boxShadow: '0px 0px 15px rgba(108, 71, 255, 0.75)',
                      borderColor: 'primary.300'
                    }}
                  >
                    {theme.name}
                  </Button>
                );
              })}
            </Flex>
          </Box>

          {showCode ? (
            <>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignContent="center"
                pos="relative"
                width={704}
                my="36px"
                height={397}
              >
                <CodeWindow snippet={snippet} />
              </Box>
              <Box pos="relative" top={-810} left={10} display={'flex'}>
                <Button
                  zIndex={100}
                  rounded="full"
                  alignSelf="center"
                  py="24px"
                  px="13px"
                  height="20px"
                  lineHeight={'24px'}
                  fontWeight={700}
                  pos="absolute"
                  top={710}
                  onClick={() => {
                    {
                      !showCode
                        ? slideComponentStart()
                        : slideComponentReturn();
                    }
                    setShowCode(!showCode);
                  }}
                >
                  {showCode ? 'Hide code' : 'View code'}
                </Button>
              </Box>
            </>
          ) : (
            <Box pt="24px" pos="relative">
              <ChakraBox
                zIndex={10}
                id="component"
                as={motion.div}
                animate={controls}
                pos="relative"
                display={'flex'}
                overflow="hidden"
                width={704}
                height={445}
                top={16}
              >
                <ThemeBGLoader currentTheme={currentTheme} />
                <Box
                  pos="relative"
                  left={'10px'}
                  top="-44px"
                  display={currentTheme?.name == 'Wave' ? 'flex' : 'none'}
                >
                  <Image
                    src={'/images/components/themes/desktop/wave-image.svg'}
                    alt=""
                    width={683}
                    height={445}
                  />
                </Box>
              </ChakraBox>

              <Box pos="relative" top={-810} left={10} display={'flex'}>
                {currentTheme?.name === 'Retro' ? (
                  <Box
                    zIndex={100}
                    alignSelf="center"
                    pos="absolute"
                    top={710}
                    onClick={() => {
                      {
                        !showCode
                          ? slideComponentStart()
                          : slideComponentReturn();
                      }
                      setShowCode(!showCode);
                    }}
                    cursor="pointer"
                  >
                    <Image
                      src="/images/components/themes/retro-button.svg"
                      height={50}
                      width={159}
                      alt={currentTheme?.name}
                    />
                  </Box>
                ) : (
                  <Button
                    zIndex={100}
                    rounded="full"
                    alignSelf="center"
                    py="24px"
                    px="13px"
                    height="20px"
                    lineHeight={'24px'}
                    fontWeight={700}
                    pos="absolute"
                    top={710}
                    onClick={() => {
                      {
                        !showCode
                          ? slideComponentStart()
                          : slideComponentReturn();
                      }
                      setShowCode(!showCode);
                    }}
                  >
                    {showCode ? 'Hide code' : 'View code'}
                  </Button>
                )}
              </Box>
            </Box>
          )}

          <ChakraBox
            zIndex={10}
            id="component"
            as={motion.div}
            animate={controls}
            top={'20%'}
            right={'120px'}
            pos="absolute"
            transform={'scale(.65)'}
            filter="drop-shadow(0px 2px 25px rgba(143, 143, 143, 0.5))"
            pointerEvents={'none'}
            draggable="false"
            userSelect={'none'}
            maxW="480"
          >
            <ThemeLoader currentTheme={currentTheme} />
          </ChakraBox>
        </Flex>
      </Stack>
    </>
  );
}
