import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import { ThemeProps } from './customization';
interface ThemeBGMobileProps {
  currentTheme: ThemeProps;
}

export const ThemeBGMobileLoader = ({ currentTheme }: ThemeBGMobileProps) => {
  return (
    <>
      <Box display={currentTheme?.name == 'Light' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/light-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Dark' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/dark-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Simple' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/simple-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Wave' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/wave-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Abstract' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/abstract-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Retro' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/retro-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Neobrutalism' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/neobrutalism-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Yolo' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/mobile/yolo-bg.png`}
          alt={currentTheme?.name}
          fill
          loading="lazy"
        />
      </Box>
    </>
  );
};
