import React from 'react';
import { Box } from '@chakra-ui/react';
import { CodeBlock as Block, CopyBlock as Copy } from 'react-code-blocks';
import { colors } from '@theme/foundations';

type Props = {
  snippet: string;
  language?: string;
  hasCopy?: boolean;
};

export function CodeSnippet({
  snippet,
  language = 'jsx',
  hasCopy
}: Props): JSX.Element {
  const codeRef = React.useRef<HTMLDivElement>(null);
  const Component = hasCopy ? Copy : Block;

  React.useEffect(() => {
    if (typeof window !== 'undefined' && hasCopy && codeRef.current) {
      const code = codeRef.current;
      const button = code.querySelector('button');

      if (button) {
        button.ariaLabel = 'Copy button';
      }
    }
  }, [hasCopy]);

  return (
    <Box
      ref={codeRef}
      w="full"
      sx={{
        '*': {
          fontWeight: '500 !important',
          fontFamily: 'monospace'
        },
        '.react-syntax-highlighter-line-number': {
          color: 'rgba(255, 255, 255, 0.5)'
        },
        '.token.property': {
          color: colors.primary[300]
        }
      }}
    >
      <Component
        text={snippet}
        language={language}
        wrapLines
        // showLineNumbers
        // TODO: Add more colours as needed
        theme={{
          lineNumberColor: '',
          lineNumberBgColor: '',
          backgroundColor: 'transparent',
          textColor: colors.green[300],
          substringColor: '',
          keywordColor: colors.primary[300],
          attributeColor: '',
          selectorTagColor: '',
          docTagColor: '',
          nameColor: '',
          builtInColor: '',
          literalColor: '',
          bulletColor: '',
          codeColor: '',
          additionColor: '',
          regexpColor: '',
          symbolColor: '',
          variableColor: '',
          templateVariableColor: '',
          linkColor: '',
          selectorAttributeColor: '',
          selectorPseudoColor: '',
          typeColor: '',
          stringColor: colors.green[300],
          selectorIdColor: '',
          selectorClassColor: '',
          quoteColor: '',
          templateTagColor: colors.green[300],
          deletionColor: '',
          titleColor: '',
          sectionColor: colors.yellow[500],
          commentColor: '',
          metaKeywordColor: '',
          metaColor: '',
          functionColor: colors.blue[300],
          numberColor: ''
        }}
      />
    </Box>
  );
}
