import { SignInIcon } from '@components/common/icons';
import React, { useEffect } from 'react';
import { Box, Button, Center, Flex, Icon, Text } from '@chakra-ui/react';
import { CodePreview } from './code-preview';
import Link from 'next/link';
import { BookOpenIcon } from '@heroicons/react/outline';
import { useSegment } from '@hooks/use-segment';
import { useRouter } from 'next/router';
import {
  SignUpIcon,
  UserProfileIcon,
  UserButtonIcon
} from '@components/layout/header/icons';

const frameworks = [
  'Next.js',
  'React',
  'Node.js',
  'Gatsby',
  'Remix',
  'Redwood'
];

type FrameworkSwitcherProps = {
  currentFramework: string;
  setCurrentFramework: (framework: string) => void;
};

export function FrameworkSwitcher({
  currentFramework,
  setCurrentFramework
}: FrameworkSwitcherProps): JSX.Element | null {
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    setShouldRender(true);
  }, []);

  // Workaround to prevent `Hydration failed because the initial UI does not match what was rendered on the server.`
  if (!shouldRender) return null;

  return (
    <Flex
      rounded={{ md: 'xl' }}
      justifyContent="space-evenly"
      w="full"
      maxW="98vw"
    >
      {frameworks.map(name => (
        <Center
          key={name}
          as="button"
          flexDir="column"
          gap={3}
          py={4}
          px="21.5px"
          flex={{ base: 1, md: 'unset' }}
          onClick={() => {
            setCurrentFramework(name);
          }}
          zIndex={100}
        >
          <Text
            textStyle={{ base: 'paragraph-xs', sm: 'paragraph-sm' }}
            color={currentFramework === name ? '#17CCFC' : 'white'}
            filter="drop-shadow(0px 0px 4px #17CCFC)"
            fontSize={'14px'}
          >
            {name}
          </Text>
        </Center>
      ))}
    </Flex>
  );
}

const componentList = [
  {
    name: '<SignIn/>',
    description:
      'An optimized sign in experience thats also completely customizable.  Choose the auth strategies you need — passwords, email codes or links, OAuth and more. ',
    icon: SignInIcon,
    image: '/images/components/component-sign_in.svg'
  },
  {
    name: '<SignUp/>',
    description:
      'Enable users to sign up using the authentication strategy you need — email addresses, phone numbers, usernames, passwords, email codes or links, OAuth, and more.',
    icon: SignUpIcon,
    image: '/images/components/component-sign_up.svg'
  },
  {
    name: '<UserProfile/>',
    description:
      'Enable users to manage their profile and security settings. Mount in your application or open as a modal.',
    icon: UserProfileIcon,
    image: '/images/components/component-user_profile.svg'
  },
  {
    name: '<UserButton/>',
    description:
      'Enable users to switch accounts, manage their own accounts, and sign out. Mount in your application or open as a modal.',
    icon: UserButtonIcon,
    image: '/images/components/component-user_button.svg'
  }
];

type Component = {
  name: string;
  description: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  image: string;
};

export function AuthUserComponents(): JSX.Element {
  const analytics = useSegment();
  const [currentFramework, setCurrentFramework] =
    React.useState<string>('Next.js');
  const [currentComponent, setCurrentComponent] = React.useState<Component>(
    componentList[0]
  );
  const [snippet, setSnippet] = React.useState<string>();
  const [component, setComponent] = React.useState<string>();

  const { query } = useRouter();

  useEffect(() => {
    if (query.component) {
      if (query.component === 'SignIn') {
        setCurrentComponent(componentList[0]);
      } else if (query.component === 'SignUp') {
        setCurrentComponent(componentList[1]);
      } else if (query.component === 'UserProfile') {
        setCurrentComponent(componentList[2]);
      } else if (query.component === 'UserButton') {
        setCurrentComponent(componentList[3]);
      }
    }
  }, [query.component]);

  const sdkImport: string = React.useMemo(() => {
    switch (currentFramework) {
      case 'React':
      case 'Redwood':
      case 'Gatsby':
        return 'clerk-react';
      case 'Next.js':
        return 'nextjs';
      case 'Remix':
        return 'remix';
      default:
        return 'clerk-react';
    }
  }, [currentFramework]);

  React.useEffect(() => {
    const componentSwitcher = () => {
      switch (currentComponent.name) {
        case '<SignUp/>':
          return setComponent('SignUp');
        case '<SignIn/>':
          return setComponent('SignIn');
        case '<UserButton/>':
          return setComponent('UserButton');
        case '<UserProfile/>':
          return setComponent('UserProfile');
        default:
          return setComponent('SignUp');
      }
    };
    if (currentComponent) {
      componentSwitcher();
    }
  }, [currentComponent]);

  React.useEffect(() => {
    if (component && currentFramework) {
      setSnippet(
        `// ${currentFramework} app
import { ${component} } from '@clerk/${sdkImport}';

export default function Page() {
  return <${component} />;
}`
      );
    }
  }, [component, currentFramework, sdkImport]);

  return (
    <Box w="full">
      <CodePreview
        componentList={componentList}
        setCurrentComponent={setCurrentComponent}
        currentComponent={currentComponent}
        currentFramework={currentFramework}
        setCurrentFramework={setCurrentFramework}
        snippet={snippet}
        header={'Auth & User components'}
        subheader={
          'Everything you need to onboard your users, and let them manage their account.'
        }
        imgDirection="right"
        icon={'/images/components/lock-icon.svg'}
      />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent={{ base: 'center', xl: 'flex-start' }}
        w="full"
        gap={4}
        pos="relative"
      >
        <Link
          legacyBehavior
          href={process.env.NEXT_PUBLIC_DASHBOARD_URL as string}
          passHref
        >
          <Button
            as="a"
            w={{ base: '84vw', md: 225 }}
            variant="base"
            h={12}
            fontSize="md"
            zIndex={100}
            onClick={() => {
              analytics.track('Marketing_Home_Auth+Users_Docs Button Clicked', {
                surface: 'Marketing',
                location: 'Home'
              });
            }}
          >
            Start Building
          </Button>
        </Link>

        <Link href="/docs/authentication/overview" passHref>
          <Button
            leftIcon={<Icon as={BookOpenIcon} boxSize={5} />}
            variant={'white'}
            zIndex={100}
            w={{ base: '84vw', md: 225 }}
            h={12}
            fontSize="md"
            onClick={() => {
              analytics.track('Marketing_Home_Auth+Users_Docs Button Clicked', {
                surface: 'Marketing',
                location: 'Home'
              });
            }}
          >
            Read documentation
          </Button>
        </Link>
      </Flex>
    </Box>
  );
}
