import { Box } from '@chakra-ui/react';
import Image from 'next/image';

interface Props {
  currentComponent: {
    name: string;
  };
}

export const ImageLoader = ({ currentComponent }: Props) => {
  return (
    <>
      <Box display={currentComponent?.name == '<SignIn/>' ? 'flex' : 'none'}>
        <Image
          src={'/images/components/component-sign_in.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box display={currentComponent?.name == '<SignUp/>' ? 'flex' : 'none'}>
        <Image
          src={'/images/components/component-sign_up.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box
        display={currentComponent?.name == '<UserButton/>' ? 'flex' : 'none'}
      >
        <Image
          src={'/images/components/component-user_button.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box
        display={currentComponent?.name == '<UserProfile/>' ? 'flex' : 'none'}
      >
        <Image
          src={'/images/components/component-user_profile.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box
        display={
          currentComponent?.name == '<OrganizationProfile/>' ? 'flex' : 'none'
        }
      >
        <Image
          src={'/images/components/org-members.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
      <Box
        display={
          currentComponent?.name == '<OrganizationSwitcher/>' ? 'flex' : 'none'
        }
      >
        <Image
          src={'/images/components/org-switcher.svg'}
          alt={currentComponent?.name}
          fill
          loading="lazy"
        />
      </Box>
    </>
  );
};
