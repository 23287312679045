import { Flex, Heading, Text, Box, Icon, Button } from '@chakra-ui/react';
import { SVGProps } from 'react';
import { ThemeProps } from './customization';
import { Component } from './code-preview';
import { useRouter } from 'next/router';

interface DescriptionBlockProps {
  name: string;
  description: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

export const DescriptionBlock = ({
  name,
  icon,
  description
}: DescriptionBlockProps): JSX.Element => {
  const { pathname } = useRouter();
  return (
    <Box pl={{ base: '10%', xl: '0' }} pr={{ base: '10%', md: '0' }}>
      <Box
        h="32px"
        w="32px"
        bg="#17CCFC"
        opacity={'80%'}
        borderRadius="6px"
        pos="relative"
      ></Box>
      <Box
        h="32px"
        w="32px"
        bg="#6C47FF"
        opacity={'65%'}
        borderRadius="6px"
        pos="relative"
        top="-36px"
        right="-4px"
        display={'flex'}
        justifyItems="center"
        alignItems={'center'}
      ></Box>
      <Icon
        as={icon}
        color="white"
        h="16px"
        w="16px"
        pos="relative"
        left="3"
        opacity={'100%'}
        top="-66px"
      />
      <Box pos="relative" top="-40px">
        <Text
          borderLeft={'2px'}
          pl="2"
          borderLeftColor={'primary.500'}
          fontWeight={'bold'}
          mb={4}
        >
          {name}
        </Text>
        <Text
          maxW={{ base: '', md: '460px' }}
          minH="72px"
          color={pathname === '/' ? 'dark-text' : 'inherit'}
        >
          {description}
        </Text>
      </Box>
    </Box>
  );
};

interface ThemeSelectButtonsProps {
  themeList: ThemeProps[];
  currentTheme: ThemeProps;
  handleOnClick?: (theme: ThemeProps) => void;
}

export const ThemeSelectButtons = ({
  themeList,
  currentTheme,
  handleOnClick
}: ThemeSelectButtonsProps): JSX.Element => {
  return (
    <Flex
      flexWrap="wrap"
      justifyContent={
        currentTheme?.name === '<OrganizationProfile/>' ||
        currentTheme?.name === '<OrganizationSwitcher/>'
          ? 'center'
          : { base: 'center', xl: 'space-evenly' }
      }
      zIndex={10}
      pos="relative"
      top={4}
      w="full"
      gap={
        currentTheme?.name === '<OrganizationProfile/>' ||
        currentTheme?.name === '<OrganizationSwitcher/>'
          ? 4
          : 0
      }
    >
      {themeList.map((theme: ThemeProps) => {
        return (
          <Button
            key={theme.name}
            py="10px"
            px="16px"
            mx={1}
            my={
              currentTheme?.name === '<OrganizationProfile/>' ||
              currentTheme?.name === '<OrganizationSwitcher/>'
                ? 0
                : { base: 2, xl: 0 }
            }
            leftIcon={
              currentTheme?.icon && <Icon as={theme.icon} boxSize={4} />
            }
            color={currentTheme?.name === theme.name ? 'white' : 'primary.500'}
            bg={currentTheme?.name === theme.name ? 'primary.500' : 'white'}
            rounded={'2xl'}
            h="32px"
            boxShadow={
              currentTheme?.name === theme.name
                ? '0px 0px 15px rgba(108, 71, 255, 0.75)'
                : '0px 1px 2px rgba(57, 67, 86, 0.08);'
            }
            textStyle="paragraph-sm"
            fontSize="12px"
            borderColor={
              currentTheme?.name === theme.name ? 'primary.300' : 'transparent'
            }
            onClick={() => {
              handleOnClick && handleOnClick(theme);
            }}
            _hover={{
              color: 'white',
              bg: 'primary.500',
              boxShadow: '0px 0px 15px rgba(108, 71, 255, 0.75)',
              borderColor: 'primary.300'
            }}
          >
            {theme.name}
          </Button>
        );
      })}
    </Flex>
  );
};

interface ComponentSelectProps {
  componentList: Component[];
  currentComponent: Component;
  handleOnClick?: (component: Component) => void;
}

export const ComponentSelectButtons = ({
  componentList,
  currentComponent,
  handleOnClick
}: ComponentSelectProps): JSX.Element => {
  return (
    <Flex
      flexWrap="wrap"
      justifyContent={
        currentComponent?.name == '<OrganizationProfile/>' ||
        currentComponent?.name == '<OrganizationSwitcher/>'
          ? 'center'
          : { base: 'center', xl: 'space-evenly' }
      }
      zIndex={10}
      pos="relative"
      top={4}
      w="full"
      gap={
        currentComponent?.name == '<OrganizationProfile/>' ||
        currentComponent?.name == '<OrganizationSwitcher/>'
          ? 4
          : 0
      }
    >
      {componentList.map((component: Component) => {
        return (
          <Button
            key={component.name}
            py="10px"
            px="16px"
            mx={1}
            my={
              currentComponent?.name == '<OrganizationProfile/>' ||
              currentComponent?.name == '<OrganizationSwitcher/>'
                ? 0
                : { base: 2, xl: 0 }
            }
            leftIcon={
              currentComponent?.icon && <Icon as={component.icon} boxSize={4} />
            }
            color={
              currentComponent?.name === component.name
                ? 'white'
                : 'primary.500'
            }
            bg={
              currentComponent?.name === component.name
                ? 'primary.500'
                : 'white'
            }
            rounded={'2xl'}
            h="32px"
            boxShadow={
              currentComponent?.name === component.name
                ? '0px 0px 15px rgba(108, 71, 255, 0.75)'
                : '0px 1px 2px rgba(57, 67, 86, 0.08);'
            }
            textStyle="paragraph-sm"
            fontSize="12px"
            borderColor={
              currentComponent?.name === component.name
                ? 'primary.300'
                : 'transparent'
            }
            onClick={() => {
              if (handleOnClick) {
                handleOnClick(component);
              }
            }}
            _hover={{
              color: 'white',
              bg: 'primary.500',
              boxShadow: '0px 0px 15px rgba(108, 71, 255, 0.75)',
              borderColor: 'primary.300'
            }}
          >
            {component.name}
          </Button>
        );
      })}
    </Flex>
  );
};

export const Header = ({
  header,
  subheader
}: {
  header: string;
  subheader: string;
}): JSX.Element => {
  return (
    <Flex
      justifyContent={{ base: 'center', xl: 'flex-start' }}
      flexDir="column"
      alignItems={{ base: 'center', xl: 'flex-start' }}
      maxW={{ lg: '470px' }}
    >
      <Heading
        as="h3"
        size="h3"
        pt="24px"
        pb="16px"
        textAlign={{ base: 'center', xl: 'left' }}
        px={{ base: '16px', xl: '0px' }}
        maxW={{ base: '60%', lg: '100%' }}
      >
        {header}
      </Heading>
      <Text
        textAlign={{ base: 'center', xl: 'left' }}
        fontSize={{ base: '16px', lg: '24px' }}
        fontFamily={'heading'}
        pb="30px"
      >
        {subheader}
      </Text>
    </Flex>
  );
};
