import { Box } from '@chakra-ui/react';
import Image from 'next/image';
import { ThemeProps } from './customization';
interface ThemeLoaderProps {
  currentTheme: ThemeProps;
}

export const ThemeLoader = ({ currentTheme }: ThemeLoaderProps) => {
  return (
    <>
      <Box display={currentTheme?.name == 'Light' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/light.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Dark' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/dark.svg`}
          alt={currentTheme?.name}
          height={'100'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Simple' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/simple.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Wave' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/wave.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Abstract' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/abstract.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Retro' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/retro.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box
        display={currentTheme?.name == 'Neobrutalism' ? 'flex' : 'none'}
        transform={'scale(.9)'}
      >
        <Image
          src={`/images/components/themes/neobrutalism.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
      <Box display={currentTheme?.name == 'Yolo' ? 'flex' : 'none'}>
        <Image
          src={`/images/components/themes/yolo.svg`}
          alt={currentTheme?.name}
          height={'600'}
          width="460"
          loading="lazy"
        />
      </Box>
    </>
  );
};
