export function SignInIcon(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 17C16.4477 17 16 16.5523 16 16L16 4C16 3.44772 16.4477 3 17 3C17.5523 3 18 3.44772 18 4L18 16C18 16.5523 17.5523 17 17 17ZM9.29289 13.7071C8.90237 13.3166 8.90237 12.6834 9.29289 12.2929L10.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9L10.5858 9L9.29289 7.70711C8.90237 7.31658 8.90237 6.68342 9.29289 6.29289C9.68342 5.90237 10.3166 5.90237 10.7071 6.29289L13.7071 9.29289C13.8946 9.48043 14 9.73478 14 10C14 10.2652 13.8946 10.5196 13.7071 10.7071L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071Z"
        fill="currentColor"
      />
    </svg>
  );
}
